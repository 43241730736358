<template>
  <div>
    <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
      <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
        <el-form-item label="上级菜单" prop="parent_id">
          <el-select v-model="formData.parent_id" filterable default-first-option placeholder="请选择上级菜单" @change="parentSel($event)">
            <el-option v-for="item in renderCateList" :key="item._id" :label="item.name" :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级菜单" prop="children_id" v-if="childrenList.length > 0 && formData.parent_id != ''">
          <el-select v-model="formData.children_id" filterable default-first-option placeholder="请选择上级菜单">
            <el-option v-for="item in childrenList" :key="item._id" :label="item.name" :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="formData.name" clearable maxlength="20" placeholder="请输入菜单名称" />
        </el-form-item>
        <el-form-item label="菜单描述" prop="describe">
          <el-input v-model="formData.describe" clearable type="textarea" placeholder="请输入菜单描述" />
        </el-form-item>
        <el-form-item label="下拉弹框" prop="dropDownShow" v-if="formData.parent_id == ''">
          <el-switch class="has-text" style="margin-left: 6px" v-model="formData.dropDownShow" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
        <el-form-item v-if="formVisible" label="菜单地址" prop="image">
          <JumpDialog ref="jump" @sel="sel"></JumpDialog>
        </el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-switch class="has-text" style="margin-left: 6px" v-model="formData.status" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="formData.sort" :step="1" :min="0" :max="999" step-strictly></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="padding-left: 140px">
        <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
        <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import JumpDialog from '@/components/JumpDialog'
import { mapMutations, mapGetters } from 'vuex'
import http from '@/utils/cloud'

export default {
  components: {
    JumpDialog
  },
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      childrenList: [],
      renderCateList: [],
      formVisible: false, //表单显示状态
      formData: {
        page: ''
      }, //表单数据
      rules: {
        name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        describe: [{ required: false, message: '请输入菜单描述', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
        dropDownShow: [{ required: false }],
        status: [{ required: true }],
        page: [{ required: true, message: '请选择菜单链接', trigger: 'blur' }]
      },
      jump: ''
    }
  },
  props: {
    cateList: Array
  },
  computed: {
    formTitle() {
      return this.formData._id ? '编辑菜单' : '添加菜单'
    }
  },
  watch: {
    formVisible(state) {
      if (state) {
        //菜单处理
        const renderList = this.cateList.filter(item => !item.parent_id && item._id != this.formData._id)
        renderList.unshift({
          _id: '',
          name: '顶级菜单'
        })
        this.renderCateList = renderList
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            parent_id: '',
            sort: 0,
            status: 1
          }
        }
      } else {
        this.$refs.dataForm.resetFields()
        this.formData = {} //清空表单
      }
    }
  },
  methods: {
    parentSel(val) {
      if (val) {
        this.childrenList = []
        this.cateList.forEach(item => {
          if (item._id == val) {
            if (item.children) {
              this.childrenList = item.children
            }
          }
        })
      }
    },
    sel(jump) {
      this.formData.page = jump
    },

    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64)
      if (result) {
        this.formData.image = result.url
      } else {
        this.$message.error('图片失败')
        this.formData.image = ''
      }
    },
    submit() {
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
        const data = { ...this.formData }
        delete data.renderName

        if (data.children) {
          delete data.children
        }
        const operation = data._id ? 'updateCategory' : 'addCategory'
        const response = await http.POST('/jw-admin', {
          module: 'header',
          operation: operation,
          project: JSON.parse(this.project)._id,
          ...data
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    },
    onImageChanged(files) {
      this.formData.image = files.length > 0 ? files[0] : ''
      this.$refs.dataForm.validateField('image')
    }
  }
}
</script>

<style lang="scss" scoped>
/* 加宽行 */
.form-item-widen {
  width: 650px;
}
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    /* margin-top: 5vh !important; */
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
</style>
